import { createSelector } from "reselect";
import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";
import produce from "immer";

/* -- actions -- */
export const fetchUsers = () => async dispatch => {
  dispatch(fetchUsersRequest());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_utenti",
    });

    dispatch(fetchUsersSucceeded(data));
  } catch (err) {
    dispatch(fetchUsersFailed(err));
  }
};

export const {
  fetchUsersRequest,
  fetchUsersSucceeded,
  fetchUsersFailed,
} = createActions({
  FETCH_USERS_REQUEST: projectId => ({ projectId }),
  FETCH_USERS_SUCCEEDED: users => ({ users }),
  FETCH_USERS_FAILED: err => ({ err }),
});

export const {
  deleteUserRequest,
  deleteUserSucceeded,
  deleteUserFailed,
} = createActions({
  DELETE_USER_REQUEST: projectId => ({ projectId }),
  DELETE_USER_SUCCEEDED: user => ({ user }),
  DELETE_USER_FAILED: err => ({ err }),
});

export const deleteUser = (user, sendToast) => async dispatch => {
  dispatch(deleteUserRequest(user));

  try {
    await apiClient({})({
      action: "remove_utente_onetime",
      data: {
        id_utente: user.id,
      },
    });

    dispatch(deleteUserSucceeded(user));
  } catch (err) {
    dispatch(deleteUserFailed(err));
  }
};

export const resendUserInvitation = user => async () => {
  return apiClient({})({
    action: "resend_utente_onetime",
    data: {
      id_utente: user.id,
    },
  });
};

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchUsersSucceeded]: (state, { payload: { users } }) => {
      return users;
    },
    [deleteUserSucceeded]: (state, { payload: { user } }) =>
      produce(state, state => {
        state = state.filter(u => u.id !== user.id);

        return state;
      }),
  },
  []
);

export const metaReducer = handleActions(
  {
    [fetchUsersRequest]: state => {
      return {
        ...state,
        loading: true,
      };
    },
    [fetchUsersSucceeded]: state => {
      return {
        ...state,
        loading: false,
        last: new Date().getTime(),
      };
    },
  },
  {
    loading: false,
    error: false,
  }
);

/* -- selectors -- */
export const usersSelector = state => state.users;

export const userSelectorById = userId =>
  createSelector(
    usersSelector,
    users => users.find(user => userId === user.id)
  );

export const usersFetchingSelector = createSelector(
  state => state.meta,
  meta => meta.users,
  users => users.loading
);
