import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import apiClient from "utility/apiClient";

import logo from "../logo.svg";
import {
  Field,
  Container,
  Wrapper,
  SubmitBtn,
  Logo,
  ErrorMessage,
} from "./style";

import Sidekick from "components/Sidekick";
import "styled-components/macro";

export default function ResetPasswordForm(props) {
  const errorsDefault = {
    api: false,
    password: false,
    querystring: false,
    insert: false,
  };
  const [queryString, setQueryString] = useState();
  const [values, setValues] = useState({
    new_password: null,
    confirm_password: null,
  });
  const [error, setError] = useState(errorsDefault);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    try {
      const { location } = props;
      const { search } = location;
      const query = search
        .replace("?", "")
        .split("&")
        .reduce((acc, str) => {
          const index = str.indexOf("=");
          const key = str.substring(0, index);
          const value = str.substring(index + 1);
          return {
            ...acc,
            [key]: value,
          };
        }, {});

      const { password_reset_token: token, clientid } = query;
      if (token && clientid) {
        setQueryString(query);
      } else {
        setError(s => ({
          ...s,
          querystring: true,
        }));
      }
    } catch (err) {
      console.log(err);
      setError(s => ({
        ...s,
        querystring: true,
      }));
    }
  }, []);

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { new_password, confirm_password } = values;

    if (!(new_password && confirm_password)) {
      setError(s => ({
        ...s,
        insert: true,
      }));
      return;
    }

    if (new_password !== confirm_password) {
      setError(s => ({
        ...s,
        password: true,
      }));
      return;
    }

    setError(errorsDefault);
    setLoading(true);
    try {
      await apiClient({})({
        action: "reset_password",
        data: {
          new_password,
          ...queryString,
        },
      });
      setSuccess(true);
    } catch (err) {
      setError(s => ({
        ...s,
        api: err.message,
      }));
    }
    setLoading(false);
  }

  function ErrorText() {
    if (error.password) {
      return (
        <ErrorMessage>Le password inserite non corrispondono</ErrorMessage>
      );
    }
    if (error.insert) {
      return <ErrorMessage>Compilare entrambi i campi</ErrorMessage>;
    }
    if (error.api) {
      return <ErrorMessage>{error.api}</ErrorMessage>;
    }
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <Logo src={logo} />
        {error.querystring && (
          <Sidekick.Title>
            Link di ripristino password non valido
          </Sidekick.Title>
        )}
        {success && (
          <>
            <Sidekick.Title>
              La password é stata cambiata con successo.
            </Sidekick.Title>
            <SubmitBtn onClick={() => setRedirect(true)}>Vai a login</SubmitBtn>
            {redirect && <Redirect to="/login" />}
          </>
        )}
        {!(error.querystring || success) && (
          <>
            <Sidekick.Text>Inserisci la tua nuova password</Sidekick.Text>
            <form onSubmit={handleSubmit}>
              <Field
                label="Nuova password"
                name="new_password"
                value={values["new_password"]}
                type="password"
                onChange={e => handleChange("new_password", e.target.value)}
                disabled={loading}
              />
              <Field
                label="Conferma password"
                name="confirm_password"
                value={values["confirm_password"]}
                type="password"
                onChange={e => handleChange("confirm_password", e.target.value)}
                disabled={loading}
              />
              <ErrorText />
              <SubmitBtn type="submit">
                {loading ? "Attendere" : "Conferma"}
              </SubmitBtn>
            </form>
          </>
        )}
      </Wrapper>
    </Container>
  );
}
