import React, { createContext, useRef, useEffect } from "react";

export const datGuiContext = createContext();
export const { Provider, Consumer } = datGuiContext;

const DAT = window.dat;

export default function DatGuiProvider(props) {
  const gui = useRef();
  const isActive = process.env.REACT_APP_FEATURES_DAT_GUI;

  useEffect(() => {
    if (isActive) {
      gui.current = new DAT.GUI({ width: 400, autoPlace: false });

      const rootEl = document.querySelector("#root");
      rootEl.appendChild(gui.current.domElement);

      gui.current.domElement.style.zIndex = 9999999;
      gui.current.domElement.style.position = "fixed";
      gui.current.domElement.style.top = 0;
      gui.current.domElement.style.right = 0;

      return () => {
        if (gui.current) {
          gui.current.domElement.remove();
          gui.current.destroy();
        }
      };
    }
  }, []);

  return isActive ? (
    <Provider
      value={{
        datGui: gui,
      }}
    >
      {props.children}
    </Provider>
  ) : (
    props.children
  );
}
