import React from "react";
import "styled-components/macro";

import posed, { PoseGroup } from "react-pose";

import Btn from "components/Btn";
import Icon from "components/Icon";

import * as S from "./styles";

const ICONS = {
  SUCCESS: "check",
  ERROR: "priority_high",
};

const COLORS = {
  SUCCESS: "green",
  ERROR: "red",
};

function Toast(props) {
  const { items = [], onCloseClick, hide } = props;
  const [index, setIndex] = React.useState(0);

  function next() {
    setIndex(index + 1 >= items.length ? 0 : index + 1);
  }

  function prev() {
    setIndex(index - 1 < 0 ? items.length - 1 : index - 1);
  }

  React.useEffect(() => {
    if (items.length === 0) {
      setIndex(0);
    }
  }, [items]);

  if (items.length === 0) return null;

  return (
    <Container pose={hide ? "exit" : "enter"} initialPose={"exit"}>
      <S.Close>
        <Btn circle icon="close" size="small" onClick={onCloseClick} />
      </S.Close>

      <PoseGroup>
        {
          <Single key={items[index].id}>
            {typeof items[index] === "string" ? (
              items[index]
            ) : (
              <div
                css={`
                  display: flex;
                  align-items: center;
                  padding: 1rem;

                  > * + * {
                    margin-left: 1rem;
                  }
                `}
              >
                <div>
                  <Icon
                    size="small"
                    color={COLORS[items[index].type]}
                    circle
                    icon={ICONS[items[index].type]}
                  />
                </div>
                <div> {items[index].message} </div>
              </div>
            )}
          </Single>
        }
      </PoseGroup>

      {items.length > 1 && (
        <S.Flex>
          <div data-testid="index">
            <strong>{index + 1}</strong>/{items.length}
          </div>

          <S.Buttons>
            <Btn
              data-testid="prev"
              onClick={prev}
              circle
              icon="keyboard_arrow_left"
            />
            <Btn
              data-testid="next"
              onClick={next}
              circle
              icon="keyboard_arrow_right"
            />
          </S.Buttons>
        </S.Flex>
      )}
    </Container>
  );
}

const Container = posed(S.Container)({
  enter: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 100,
    opacity: 0,
  },
});

const Single = posed(S.Single)({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 30,
    opacity: 0,
  },
});

export default Toast;

export const toastContext = React.createContext();

export function ToastProvider(props) {
  const [toasts, setToasts] = React.useState([]);

  const sendToast = React.useCallback(
    newToast => {
      const id = parseInt(Math.random() * 10.0, 10);

      setToasts([
        {
          ...newToast,
          id,
        },
        ...toasts,
      ]);
    },
    [toasts, setToasts]
  );

  const sendToasts = React.useCallback(
    newToasts => {
      setToasts([
        ...newToasts.map(toast => {
          const id = parseInt(Math.random() * 10.0, 10);

          return {
            ...toast,
            id,
          };
        }),
        ...toasts,
      ]);
    },
    [toasts, setToasts]
  );

  const dismiss = React.useCallback(() => {
    setToasts([]);
  }, [setToasts]);

  React.useEffect(() => {
    let t;

    if (toasts.length > 0) {
      t = setTimeout(dismiss, 3000);
    }

    return () => clearTimeout(t);
  }, [toasts]);

  return (
    <toastContext.Provider
      value={{
        sendToast,
        sendToasts,
        dismissToast: dismiss,
        toasts,
      }}
    >
      {props.children}
      <Toast items={toasts} onCloseClick={dismiss} />
    </toastContext.Provider>
  );
}
