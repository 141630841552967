import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import posed from "react-pose";

import Icon from "components/Icon";

function Modal(props) {
  const { onCloseClick, children, widescreen = false } = props;

  return createPortal(
    <Container>
      <Bg onClick={onCloseClick} />
      <PosedWrapper widescreen={widescreen} pose={"enter"} initialPose={"exit"}>
        <Close onClick={onCloseClick}>
          <Icon icon="close" size="medium" />
        </Close>
        <Content>{children}</Content>
      </PosedWrapper>
    </Container>,
    document.getElementById("modal-container")
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  overflow-y: auto;
`;

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Close = styled.div`
  text-align: right;
  position: absolute;
  right: -2rem;
  top: 0;
  cursor: pointer;
`;

const Wrapper = styled.div`
  width: ${props => (props.widescreen ? "90%" : "70%")};
  left: 0;
  right: 0;
  margin-left: auto;
  background-color: #333;
  position: absolute;
  top: ${props => (props.widescreen ? "30px" : "64px")};
  padding: 2rem;
  margin: 0 auto;
  margin-bottom: 64px;
  border-radius: 6px;
  max-width: ${props => (props.widescreen ? "initial" : "720px")};
  min-width: 350px;

  z-index: 2001;
`;

const PosedWrapper = posed(Wrapper)({
  enter: {
    y: "0%",
    opacity: 1,
  },
  exit: {
    y: "100%",
    opacity: 0,
  },
});

const Content = styled.div`
  padding: 0;
`;

export default Modal;
