import styled from "styled-components";

export const Container = styled.div`
  padding: 0.5rem 1rem;
  background-color: #333;

  border: 1px solid #222;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.34);

  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 320px;
  border-radius: 6px;
  z-index: 1000;
`;

export const Single = styled.div`
  font-size: 14px;
`;

export const Buttons = styled.div`
  display: flex;

  > * + * {
    margin-left: 10px;
  }

  > button {
    color: #999;
    background-color: #ccc;
    border: none;
    width: 32px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  position: absolute;
  top: -2rem;
  right: 0;
`;
