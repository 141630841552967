import produce from "immer";
import { createSelector } from "reselect";
import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";

import { fetchMarkers } from "store/markers";
import { fetchMeasurements } from "store/measurements/actions";
import { fetchObjects } from "store/objects";
import { fetchViews } from "store/views";

/* -- actions -- */

// fetching
export const fetchTrashbin = rilievoId => async dispatch => {
  dispatch(fetchTrashbinRequest());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_cestino",
      data: {
        id_rilievo: rilievoId,
      },
    });

    dispatch(fetchTrashbinSucceeded(data));
  } catch (err) {
    dispatch(fetchTrashbinFailed(err));
  }
};

export const {
  fetchTrashbinRequest,
  fetchTrashbinSucceeded,
  fetchTrashbinFailed,
} = createActions({
  FETCH_TRASHBIN_REQUEST: null,
  FETCH_TRASHBIN_SUCCEEDED: data => ({ data }),
  FETCH_TRASHBIN_FAILED: error => ({ error }),
});

export const { toggleTrashbin } = createActions({
  TOGGLE_TRASHBIN: null,
});

// restore actions
const typesLookup = {
  marker: 2,
  dati: 3,
  obj: 4,
  misure: 5,
  viste: 7,
};

export const restoreTrashbinItem = (
  item,
  itemType,
  rilievoId
) => async dispatch => {
  dispatch(restoreTrashbinItemRequest(item, itemType));

  try {
    await apiClient({ cache: false })({
      action: "ripristina_cestino",
      data: {
        id_oggetto: item.id,
        tipo_oggetto: typesLookup[itemType],
      },
    });

    dispatch(restoreTrashbinItemSucceeded(item, itemType));

    switch (itemType) {
      case "marker":
        dispatch(fetchMarkers({ rilievoId }));
        return;
      case "misure":
        dispatch(fetchMeasurements({ rilievoId }));
        return;
      case "obj":
        dispatch(fetchObjects({ rilievoId }));
        return;
      case "viste":
        dispatch(fetchViews({ rilievoId }));
        return;
      default:
        return;
    }
  } catch (err) {
    dispatch(restoreTrashbinItemFailed(err, item, itemType));
  }
};

export const {
  restoreTrashbinItemRequest,
  restoreTrashbinItemSucceeded,
  restoreTrashbinItemFailed,
} = createActions({
  RESTORE_TRASHBIN_ITEM_REQUEST: (item, itemType) => ({ item, itemType }),
  RESTORE_TRASHBIN_ITEM_SUCCEEDED: (item, itemType) => ({ item, itemType }),
  RESTORE_TRASHBIN_ITEM_FAILED: (error, item, itemType) => ({
    item,
    itemType,
    error,
  }),
});

/* -- reducer -- */
export const reducer = handleActions(
  {
    [fetchTrashbinRequest]: (state, action) =>
      produce(state, draft => {
        draft.data = {};
        draft.meta.loading = true;
        draft.meta.error = false;
      }),
    [fetchTrashbinSucceeded]: (state, action) =>
      produce(state, draft => {
        draft.meta.loading = false;
        draft.meta.error = false;

        draft.data = action.payload.data;
      }),
    [fetchTrashbinFailed]: (state, action) =>
      produce(state, draft => {
        draft.meta.loading = false;
        draft.meta.error = action.payload.error;
      }),
    [restoreTrashbinItemRequest]: (state, action) =>
      produce(state, draft => {
        const { item, itemType } = action.payload;
        // set loading
        draft.data[itemType].find(
          draftItem => draftItem.id === item.id
        ).meta = {
          restoring: true,
        };
      }),
    [restoreTrashbinItemSucceeded]: (state, action) =>
      produce(state, draft => {
        const { item, itemType } = action.payload;
        // set successful
        draft.data[itemType].find(
          draftItem => draftItem.id === item.id
        ).meta = {
          restored: true,
        };
      }),
    [toggleTrashbin]: (state, action) =>
      produce(state, draft => {
        draft.meta.visible = !draft.meta.visible;
      }),
  },
  {
    data: {},
    meta: {
      visible: false,
      loading: false,
      error: false,
      restoring: [],
      restored: [],
    },
  }
);

/* -- reducer -- */
export const trashbinSelector = state => state.trashbin;

export const trashbinMetaSelector = createSelector(
  trashbinSelector,
  trashbin => trashbin.meta
);

export const trashbinVisibleSelector = createSelector(
  trashbinMetaSelector,
  trashbinMeta => trashbinMeta.visible
);

export const trashbinDataSelector = createSelector(
  trashbinSelector,
  trashbin => trashbin.data
);
