import React from "react";

import styled from "styled-components";

function Table(props) {
  const { children, smallPadding } = props;
  return <Container smallPadding={smallPadding}>{children}</Container>;
}

export default Table;

const Container = styled.table`
  border-collapse: collapse;
  border: 1px solid #555;
  border-radius: 4px;

  width: 100%;
  font-size: 14px;

  thead {
    td {
      font-weight: bold;
      font-size: 11px;
      text-transform: uppercase;
    }

    border-bottom: 1px solid #444;
    background-color: #323232;
  }

  td {
    padding: ${props => (props.smallPadding ? ".5rem" : "1rem")};
    vertical-align: center;
  }

  tr {
    border-bottom: 1px solid #444;
  }
`;
