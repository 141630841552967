import React, {
  useReducer,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components";

import Modal from "components/Modal";
import Sidekick from "components/Sidekick";
import { closeIframeModal, documentsIframeSelector } from "store/documents";

export default function DocumentsIframe() {
  const dispatch = useDispatch();
  const iframeMeta = useSelector(documentsIframeSelector);
  const { visible, src, title } = iframeMeta;

  return visible ? (
    <Modal widescreen onCloseClick={() => dispatch(closeIframeModal())}>
      <Flex>
        <Sidekick.Title>{title}</Sidekick.Title>
        <a href={src} target="_blank">
          <i className="material-icons">open_in_new</i>
        </a>
      </Flex>
      <iframe
        src={src}
        style={{
          width: "100%",
          height: "90vh",
        }}
        scrolling="yes"
        name="modal_iframe"
      />
    </Modal>
  ) : null;
}

const Flex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
