function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[\s\W-]+/g, "-"); // Replace spaces, non-word characters and dashes with a single dash (-)
}

export const project = (projectId = "1") => `/projects/${projectId}`;
export const survey = ({ projectId, survey }) =>
  `${project(projectId)}/${slugify(`${survey.id}-${survey.location}`)}`;
